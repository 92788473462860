@import url("./assets/theme/theme.css");
@layer tailwind-base, antd;
@layer tailwind-base {
  @tailwind base;
}
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    font-family: "Inter", system-ui, sans-serif;
    font-size: 16px;
  }
}
@layer components {
  .menu__item {
    @apply rounded h-10 px-4 flex items-center gap-4 ease-in-out duration-200;
  }
}

.bg_login {
  background-size: cover;
  background-image: url(../public/Background2.svg);
}
